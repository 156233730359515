import React, { useEffect, useState } from "react";
import { FiMenu } from "react-icons/fi";
import { getCookies } from "../utils/cookies";
import { useNavigate } from "react-router-dom";

const Header = ({ toggleSidebar }) => {
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState(false); // State to track authentication status


  useEffect(() => {
    const checkAuthentication = async () => {
      const token = await getCookies("token");
      setIsAuthenticated(token !== undefined); // Set authenticated state based on token presence
    };

    checkAuthentication(); // Call the function to check authentication status
  }, [navigate]);

  return (
    <>
      <header className="bg-white shadow w-full sticky top-0 z-50">
        <div className="mx-auto px-4 sm:px-6 lg:px-8 flex justify-between items-center py-2">
         {isAuthenticated ? <button onClick={toggleSidebar} className="">
            <FiMenu className="text-2xl text-[#979797]" />
          </button> : <div className="flex justify-center m-auto items-center mt-5">
            <h1 className="text-lg  font-bold logoFont    text-[#EFD098] ">
              ECOROCKS ADMIN
            </h1>
          </div>}
        </div>
      </header>
     
    </>
  );
};

export default Header;
