import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import api from "../utils/api";
import { MdDelete, MdOutlineModeEdit } from "react-icons/md";
import Loader from "./loader";

// Yup validation schema for the form
const validationSchema = Yup.object({
  name: Yup.string().required("Name is required"),
});

function Metals() {
  const [metals, setMetals] = useState([]);
  const [open, setOpen] = useState(false);
  const [editingMetal, setEditingMetal] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [metalToDelete, setMetalToDelete] = useState(null);
  const [loading, setLoading] = useState(false); // Loader state

  // Fetch the metals from the backend when the component mounts
  useEffect(() => {
    fetchMetals();
  }, []);

  const fetchMetals = async () => {
    setLoading(true); // Show loader while fetching
    try {
      const response = await api.get("/variant/metal/metals");
      setMetals(response?.data?.data);
    } catch (error) {
      console.error("Error fetching metals:", error);
    } finally {
      setLoading(false); // Hide loader after fetching
    }
  };

  const handleClickOpen = (metal = null) => {
    setEditingMetal(metal);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEditingMetal(null);
  };

  const handleOpenDeleteDialog = (metal) => {
    setMetalToDelete(metal);
    setDeleteDialogOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
    setMetalToDelete(null);
  };

  const confirmDelete = async () => {
    if (metalToDelete) {
      setLoading(true); // Show loader while deleting
      try {
        const response = await api.delete(`/variant/metal/metals/${metalToDelete._id}`);
        if (response.status === 200) {
          fetchMetals();
        }
      } catch (error) {
        console.error("Error deleting metal:", error);
      } finally {
        setLoading(false); // Hide loader after deleting
        handleCloseDeleteDialog();
      }
    }
  };

  const handleSubmit = async (values, { resetForm }) => {
    setLoading(true); // Show loader while saving
    try {
      let response;
      if (editingMetal) {
        response = await api.put(`/variant/metal/metals/${editingMetal._id}`, values);
      } else {
        response = await api.post("/variant/metal/metals", values);
      }

      if (response.status === 200) {
        fetchMetals();
      }

      resetForm();
      handleClose();
    } catch (error) {
      console.error("Error saving metal:", error);
    } finally {
      setLoading(false); // Hide loader after saving
    }
  };

  return (
    <div>
      <Button variant="contained" color="primary" onClick={() => handleClickOpen(null)}>
        Add Metal
      </Button>

      {loading &&
      <Loader/>
    }
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {metals?.map((metal) => (
                <TableRow key={metal._id}>
                  <TableCell>{metal.name}</TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleClickOpen(metal)} color="primary">
                      <MdOutlineModeEdit />
                    </IconButton>
                    <IconButton onClick={() => handleOpenDeleteDialog(metal)} color="secondary">
                      <MdDelete />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

          {/* Metal Form Dialog */}
          <Dialog open={open} onClose={handleClose}>
            <DialogTitle>{editingMetal ? "Edit Metal" : "Add Metal"}</DialogTitle>
            <Formik
              initialValues={{
                name: editingMetal ? editingMetal.name : "",
              }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {() => (
                <Form>
                  <DialogContent>
                    <div>
                      <label htmlFor="name">
                        Metal Name <span>*</span>
                      </label>
                      <Field
                        type="text"
                        id="name"
                        name="name"
                        placeholder="Metal Name"
                        className="py-1 px-2.5 min-h-10 w-full rounded-md"
                      />
                      <ErrorMessage name="name" component="div" className="text-red-500" />
                    </div>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose} color="secondary">
                      Cancel
                    </Button>
                    <Button type="submit" color="primary">
                      {editingMetal ? "Update" : "Create"}
                    </Button>
                  </DialogActions>
                </Form>
              )}
            </Formik>
          </Dialog>

          {/* Delete Confirmation Dialog */}
          <Dialog open={deleteDialogOpen} onClose={handleCloseDeleteDialog}>
            <DialogTitle>Confirm Delete</DialogTitle>
            <DialogContent>
              Are you sure you want to delete the metal "{metalToDelete?.name}"?
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDeleteDialog} color="secondary">
                Cancel
              </Button>
              <Button onClick={confirmDelete} color="primary">
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
        </TableContainer>
    
    </div>
  );
}

export default Metals;
