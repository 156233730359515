import React from "react";
import "../assets/css/loader.css";

function Loader() {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <figure>
        {/* Inline style should be an object */}
        <div style={{ "--i": 1 }}></div>
        <div style={{ "--i": 2 }}></div>
        <div style={{ "--i": 3 }}></div>
        <div style={{ "--i": 4 }}></div>
        <div style={{ "--i": 5 }}></div>
        <div style={{ "--i": 6 }}></div>
        <div style={{ "--i": 7 }}></div>
        <div style={{ "--i": 8 }}></div>
        <div style={{ "--i": 9 }}></div>
        <div style={{ "--i": 10 }}></div>
        <div style={{ "--i": 11 }}></div>
        <div style={{ "--i": 12 }}></div>
      </figure>
    </div>
  );
}

export default Loader;
