import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  CircularProgress,
} from "@mui/material";
import { MdDelete, MdEdit, MdExpandMore, MdExpandLess } from "react-icons/md";
import api from "../utils/api";
import { useNavigate } from "react-router-dom";
import Loader from "./loader";

function Product() {
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false); // Loader state
  const [editingProduct, setEditingProduct] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [productToDelete, setProductToDelete] = useState(null);
  const [expandedRows, setExpandedRows] = useState({});

  // Fetch products from the API
  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    setLoading(true); // Show loader
    try {
      const response = await api.get("/product/products?shape=&minPrice=&maxPrice=&sizeId=&metalId=&currencyCode=EURO");
      setProducts(response.data?.data);
    } catch (error) {
      console.error("Error fetching products:", error);
    } finally {
      setLoading(false); // Hide loader
    }
  };

  const handleEdit = (product) => {
    setEditingProduct(product);
    setOpenModal(true);
  };

  const handleToggleExpand = (id) => {
    setExpandedRows((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const handleOpenDeleteDialog = (product) => {
    setProductToDelete(product);
    setDeleteDialogOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
    setProductToDelete(null);
  };

  const confirmDelete = async () => {
    if (productToDelete) {
      try {
        const response = await api.delete(`/product/product/${productToDelete._id}`);
        if (response.status === 200) {
          fetchProducts();
        }
      } catch (error) {
        console.error("Error deleting product:", error);
      }
    }
    handleCloseDeleteDialog();
  };

  const handleCloseModal = () => {
    setEditingProduct(null);
    setOpenModal(false);
  };

  return (
    <div>
      <Button variant="contained" color="primary" onClick={() => navigate("addproduct")}>
        Add Product
      </Button>

      {loading && (
        <Loader/>
      ) }
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Code</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Details</TableCell>
                <TableCell>Shape</TableCell>
                <TableCell>Main Image</TableCell>
                <TableCell>Min Price</TableCell>
                <TableCell>Max Price</TableCell>
                <TableCell>Variants</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {products?.map((product) => (
                <React.Fragment key={product._id}>
                  <TableRow>
                    <TableCell>{product.code}</TableCell>
                    <TableCell>{product.name}</TableCell>
                    <TableCell>
                      <div
                        dangerouslySetInnerHTML={{ __html: product.description }}
                        style={{ maxHeight: '100px', overflow: 'hidden', textOverflow: 'ellipsis' }}
                      />
                    </TableCell>
                    <TableCell>{product.details}</TableCell>
                    <TableCell>{product.shape}</TableCell>
                    <TableCell>
                      <img src={product.mainImage} alt={product.name} width="50" height="50" />
                    </TableCell>
                    <TableCell>{product.currencySign}{product.minPrice}</TableCell>
                    <TableCell>{product.currencySign}{product.maxPrice}</TableCell>
                    <TableCell>
                      <IconButton onClick={() => handleToggleExpand(product._id)}>
                        {expandedRows[product._id] ? <MdExpandLess /> : <MdExpandMore />}
                      </IconButton>
                    </TableCell>
                    <TableCell>
                      <IconButton onClick={() => navigate("addProduct?productId=" + product._id)} color="primary">
                        <MdEdit />
                      </IconButton>
                      <IconButton onClick={() => handleOpenDeleteDialog(product)} color="secondary">
                        <MdDelete />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                  {expandedRows[product._id] && (
                    <TableRow>
                      <TableCell colSpan={9}>
                        {product.variants.map((variant) => (
                          <Typography key={variant._id} variant="body2">
                            Size: {variant.size.size}, Metal: {variant.metal.name}, Prices:
                            {variant.prices.map((price) => (
                              <span key={price._id}>
                                {' '} {price.currency.code}: €{price.price}
                              </span>
                            ))}
                          </Typography>
                        ))}
                      </TableCell>
                    </TableRow>
                  )}
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
     

      {/* Delete Confirmation Dialog */}
      <Dialog open={deleteDialogOpen} onClose={handleCloseDeleteDialog}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          Are you sure you want to delete the product "{productToDelete?.name}"?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} color="secondary">
            Cancel
          </Button>
          <Button onClick={confirmDelete} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Product;
