import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  IconButton,
} from "@mui/material";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import api from "../utils/api";
import { MdDelete, MdOutlineModeEdit } from "react-icons/md";
import Loader from "./loader"; // Import the Loader component

// Yup validation schema for the form
const validationSchema = Yup.object({
  code: Yup.string().required("Code is required"),
  sign: Yup.string().required("Sign is required"),
  name: Yup.string().required("Name is required"),
});

function Currency() {
  const [currencies, setCurrencies] = useState([]);
  const [open, setOpen] = useState(false);
  const [editingCurrency, setEditingCurrency] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [currencyToDelete, setCurrencyToDelete] = useState(null);
  const [loading, setLoading] = useState(false); // Add loading state

  // Fetch the currencies from the backend when the component mounts
  useEffect(() => {
    fetchCurrencies();
  }, []);

  const fetchCurrencies = async () => {
    setLoading(true); // Set loading to true
    try {
      const response = await api.get("/currency/currencies");
      setCurrencies(response?.data?.data);
    } catch (error) {
      console.error("Error fetching currencies:", error);
    }
    setLoading(false); // Set loading to false
  };

  const handleClickOpen = (currency = null) => {
    setEditingCurrency(currency);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEditingCurrency(null);
  };

  const handleOpenDeleteDialog = (currency) => {
    setCurrencyToDelete(currency);
    setDeleteDialogOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
    setCurrencyToDelete(null);
  };

  const confirmDelete = async () => {
    if (currencyToDelete) {
      try {
        setLoading(true); // Show loader on delete
        const response = await api.delete(`/currency/currencies/${currencyToDelete._id}`);
        if (response.status === 200) {
          // Fetch currencies after successful delete
          fetchCurrencies();
        }
      } catch (error) {
        console.error("Error deleting currency:", error);
      }
      setLoading(false); // Hide loader after deletion
    }
    handleCloseDeleteDialog();
  };

  const handleSubmit = async (values, { resetForm }) => {
    setLoading(true); // Show loader on form submission
    try {
      let response;
      if (editingCurrency) {
        // Update existing currency
        response = await api.patch(
          `/currency/currencies/${editingCurrency._id}`,
          values
        );
      } else {
        // Create new currency
        response = await api.post("/currency/currencies", values);
      }

      if (response.status === 200) {
        // Fetch currencies after successful create/update
        fetchCurrencies();
      }

      resetForm();
      handleClose();
    } catch (error) {
      console.error("Error saving currency:", error);
    }
    setLoading(false); // Hide loader after form submission
  };

  return (
    <div>
      {loading && <Loader />} {/* Show Loader when loading is true */}
      <Button
        variant="contained"
        color="primary"
        onClick={() => handleClickOpen(null)}
      >
        Add Currency
      </Button>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Code</TableCell>
              <TableCell>Sign</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {currencies?.map((currency) => (
              <TableRow key={currency._id}>
                <TableCell>{currency.code}</TableCell>
                <TableCell>{currency.sign}</TableCell>
                <TableCell>{currency.name}</TableCell>

                <TableCell>
                  <IconButton
                    onClick={() => handleClickOpen(currency)}
                    color="primary"
                  >
                    <MdOutlineModeEdit />
                  </IconButton>
                  <IconButton
                    onClick={() => handleOpenDeleteDialog(currency)}
                    color="secondary"
                  >
                    <MdDelete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

        {/* Currency Form Dialog */}
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>
            {editingCurrency ? "Edit Currency" : "Add Currency"}
          </DialogTitle>
          <Formik
            initialValues={{
              code: editingCurrency ? editingCurrency.code : "",
              sign: editingCurrency ? editingCurrency.sign : "",
              name: editingCurrency ? editingCurrency.name : "",
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ errors, touched }) => (
              <Form>
                <DialogContent>
                  <div className="space-y-4">
                    <div>
                      <label htmlFor="code">
                        Currency Code <span>*</span>
                      </label>
                      <Field
                        type="text"
                        id="code"
                        name="code"
                        placeholder="Currency Code"
                        className="py-1 px-2.5 min-h-10 w-full rounded-md focus:outline-none text-[#64709B] disabled:opacity-60 text-sm"
                      />
                      <ErrorMessage
                        name="code"
                        component="div"
                        className="text-red-500"
                      />
                    </div>
                    <div>
                      <label htmlFor="sign">
                        Currency Sign <span>*</span>
                      </label>
                      <Field
                        type="text"
                        id="sign"
                        name="sign"
                        placeholder="Currency Sign"
                        className="py-1 px-2.5 min-h-10 w-full rounded-md focus:outline-none text-[#64709B] disabled:opacity-60 text-sm"
                      />
                      <ErrorMessage
                        name="sign"
                        component="div"
                        className="text-red-500"
                      />
                    </div>
                    <div>
                      <label htmlFor="name">
                        Currency Name <span>*</span>
                      </label>
                      <Field
                        type="text"
                        id="name"
                        name="name"
                        placeholder="Currency Name"
                        className="py-1 px-2.5 min-h-10 w-full rounded-md focus:outline-none text-[#64709B] disabled:opacity-60 text-sm"
                      />
                      <ErrorMessage
                        name="name"
                        component="div"
                        className="text-red-500"
                      />
                    </div>
                  </div>
                </DialogContent>

                <DialogActions>
                  <Button onClick={handleClose} color="secondary">
                    Cancel
                  </Button>
                  <Button type="submit" color="primary">
                    {editingCurrency ? "Update" : "Create"}
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </Dialog>

        {/* Delete Confirmation Dialog */}
        <Dialog
          open={deleteDialogOpen}
          onClose={handleCloseDeleteDialog}
        >
          <DialogTitle>Confirm Delete</DialogTitle>
          <DialogContent>
            Are you sure you want to delete the currency "
            {currencyToDelete?.name}
            "? 
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDeleteDialog} color="secondary">
              Cancel
            </Button>
            <Button onClick={confirmDelete} color="primary">
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </TableContainer>
    </div>
  );
}

export default Currency;
