
export const SidebarData = [
    {
        name: "Dashboard",
        href: "/dashboard",
        // icon: <img src={Dashboard} className="h-7 w-7" />
    },
    {
        name: "Product",
        href: "/product",
        // icon: <img src={Truck} className="h-8 w-8" />
    },
    {
        name: "Currency",
        href: "/currency",
        // icon: <img src={Trip} className="h-8 w-8" />
    },
    {
        name: "Metal",
        href: "/metals",
        // icon: <img src={Client} className="h-8 w-8" />
    },
    {
        name: "Size",
        href: "/sizes",
        // icon: <img src={EmployeeImage} className="h-8 w-8" />
    },
    {
        name: "Appointments",
        href: "/appointments",
        // icon: <img src={EmployeeImage} className="h-8 w-8" />
    },
    
]