import { Outlet, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import Layout from "../layout/layout";
import { getCookies } from "../utils/cookies";

export const ProtectedRoute = ({ userType }) => {

  const navigate = useNavigate();
  useEffect(() => {
    AuthHandler();
  }, [navigate]);

  const AuthHandler = async () => {
    if (await isAuthenticated()) {
      navigate("/login");
    } 
  }
  
  const isAuthenticated = async () => {
    const token = await getCookies("token");
    return  token === undefined;
  };

 
  return (
    <Layout>
      <Outlet />
     </Layout>
  );
};

