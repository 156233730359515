import * as React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import useDeviceInfoAndLocation from "../utils/useDeviceAndLocation";
import { getCookies, setCookies } from "../utils/cookies";
import { CircularProgress } from "@mui/material";
import loginImage from "../assets/image/3094352.jpg";
import toast from "react-hot-toast";

export default function Login() {
  const navigate = useNavigate();
  const { deviceInfo, location } = useDeviceInfoAndLocation();
  const [loader, setloader] = React.useState(false);

  const initialValues = {
    email: "",
    password: "",
    deviceName: deviceInfo.deviceName,
    deviceType: deviceInfo.deviceType,
    latitude: location.latitude,
    longitude: location.longitude,
  };

  const validationSchema = Yup.object({
    email: Yup.string().email("Invalid email format").required("Required"),
    password: Yup.string()
      .min(6, "Password must be at least 6 characters")
      .required("Required"),
  });
  React.useEffect(() => {
    
  }, [])
  React.useEffect(() => {
    const checkAuthentication = async () => {
      const token = await getCookies("token");
      if(token !== undefined){
        navigate("/dashboard")
      }; // Set authenticated state based on token presence
    };

    checkAuthentication(); // Call the function to check authentication status
  }, [navigate]);
  const onSubmit = async (values) => {
    setloader(true);
    try {
      const response = await axios.post(
        "https://api.ecorocksdenia.com/api/auth/login",
        values
      );

      // Assuming the response contains a token
      if (response.data.status === 200) {
        setloader(false);
        
        const token = await setCookies("token", response.data.token);
        if (token.status === true) {
            navigate(`/dashboard`);
        }
    }
} catch (error) {
    if (error.response.data?.status === 400) {
        toast.error(error.response.data?.message);
        setloader(false);
    } else {
        setloader(false);
        toast.error("Internal Server Error");
      }
    }
  };

  return (
    <div className="  flex flex-1 items-center justify-center mt-16 overflow-y-auto">
      <div className=" grid grid-cols-5   w-full max-w-5xl bg-white rounded-lg shadow-lg overflow-hidden">
        <div
          className="w-full md:col-span-3 col-span-5"
          style={{ height: "50vh" }}
        >
          <img
            src={loginImage}
            alt="Login Illustration"
            className="w-full h-full object-contain "
          />
        </div>

        <div className="w-full h-fit p-8 md:col-span-2 bg-gray-100 col-span-5 flex flex-col justify-between">
          <h2 className="text-2xl font-bold uppercase ">Login</h2>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            enableReinitialize
          >
            {(formik) => (
              <Form className="space-y-8 ">
                <div>
                  <label htmlFor="email">
                    Email <span>*</span>
                  </label>
                  <Field
                    type="email"
                    id="email"
                    name="email"
                    autoFocus
                    placeholder="Email"
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="error"
                  />
                </div>
                <div>
                  <label htmlFor="password">
                    Password <span>*</span>
                  </label>
                  <Field
                    type="password"
                    id="password"
                    name="password"
                    placeholder="Password"
                  />
                  <ErrorMessage
                    name="password"
                    component="div"
                    className="error"
                  />
                </div>

                <button
                  type="submit"
                  className="primaryButton"
                  disabled={loader}
                >
                  {loader ? (
                    <CircularProgress variant="indeterminate" />
                  ) : (
                    "Login"
                  )}
                </button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}
