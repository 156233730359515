import React, { useEffect, useState } from "react";
import { Formik, Form, Field, FieldArray } from "formik";
import * as Yup from "yup";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import Quill styles
import api from "../utils/api";
import FileMultiUpload from "./fileUpload";
import { useNavigate, useSearchParams } from "react-router-dom";
import Loader from "./loader";

const AddProduct = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [sizes, setSizes] = useState([]);
  const [metals, setMetals] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [languages] = useState([
    { code: "en", name: "English" },
    { code: "es", name: "Spanish" },
    // Add more languages as needed

  ]); // Define languages statically
  const [editData, setEditData] = useState();
  const [loading, setLoading] = useState(false); // Loading state

  useEffect(() => {
    const productId = searchParams.get("productId");
    fetchData();
    if (productId !== null) {
      fetchProductById(productId);
    }
  }, []);

  const fetchProductById = async (id) => {
    try {
      setLoading(true); // Start loader
      const response = await api.get(`product/adminProduct/${id}`);
      if (response?.data?.status === 200) {
        setEditData(response.data?.data); // Assuming data contains the product details
      }
    } catch (error) {
      console.error("Error fetching product:", error);
    } finally {
      setLoading(false); // Stop loader
    }
  };

  const fetchData = async () => {
    try {
      setLoading(true); // Start loader
      const sizesData = await api.get("/variant/sizes/sizes");
      const metalsData = await api.get("/variant/metal/metals");
      const currenciesData = await api.get("/currency/currencies");
      setSizes(sizesData?.data?.data);
      setMetals(metalsData?.data?.data);
      setCurrencies(currenciesData?.data?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false); // Stop loader
    }
  };

  const initialValues = {
    code: "",
    name: {}, // Object to store names in different languages
    description: {}, // Object to store descriptions in different languages
    details: {},
    mainImage: "",
    images: [],
    shape: "",
    variants: [
      {
        size: "",
        metal: "",
        prices: currencies.map((currency) => ({
          currency: currency._id,
          price: "",
        })),
      },
    ],
  };

  const validationSchema = Yup.object({
    code: Yup.string().required("Code is required"),
    name: Yup.object().shape(
      languages.reduce((acc, lang) => {
        acc[lang.code] = Yup.string().required(`${lang.name} name is required`);
        return acc;
      }, {})
    ),
    description: Yup.object().shape(
      languages.reduce((acc, lang) => {
        acc[lang.code] = Yup.string().required(`${lang.name} description is required`);
        return acc;
      }, {})
    ),
    details: Yup.object().shape(
      languages.reduce((acc, lang) => {
        acc[lang.code] = Yup.string().required(`${lang.name} details are required`);
        return acc;
      }, {})
    ),
    shape: Yup.string().required("Shape is required"),
    variants: Yup.array().of(
      Yup.object().shape({
        size: Yup.string().required("Size is required"),
        metal: Yup.string().required("Metal is required"),
        prices: Yup.array().of(
          Yup.object().shape({
            currency: Yup.string().required("Currency is required"),
            price: Yup.number().required("Price is required").positive(),
          })
        ),
      })
    ),
  });

  const edit = searchParams.get("productId") !== null;

  const onSubmit = async (values) => {
    const productId = searchParams.get("productId");
    try {
      setLoading(true); // Start loader
      const response = productId
        ? await api.patch(`product/product/${productId}`, values) // Update product
        : await api.post("product/product", values);

      if (response.data?.status === 200) {
        navigate("/product");
      }
    } catch (error) {
      console.error("Error submitting product:", error);
    } finally {
      setLoading(false); // Stop loader
    }
  };

  return (
    <>
      {loading && <Loader />} {/* Show loader when loading */}
      <Formik
        initialValues={editData || initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({ values, setFieldValue, errors }) => (
          <Form className="p-6 bg-white rounded-lg shadow-md">
            <h2 className="text-2xl font-semibold mb-4">
              {edit ? "Update" : "Create"} Product
            
            </h2>
            {/* Product Fields */}
            <div className="mb-4">
              <label className="block mb-1">Code</label>
              <Field type="text" name="code" className="w-full" />
            </div>

            {/* Dynamic fields for each language */}
            {languages.map((lang) => (
              <div key={lang.code} className="mb-4">
                <label className="block mb-1">Name ({lang.name})</label>
                <Field type="text" name={`name.${lang.code}`} className="w-full" />
              </div>
            ))}

            {languages.map((lang) => (
              <div key={lang.code} className="mb-4">
                <label className="block mb-1">Description ({lang.name})</label>
                <ReactQuill
                  value={values.description[lang.code]}
                  onChange={(content) => setFieldValue(`description.${lang.code}`, content)}
                  className="bg-white rounded "
                />
              </div>
            ))}

            {languages.map((lang) => (
              <div key={lang.code} className="mb-4">
                <label className="block mb-1">Details ({lang.name})</label>
                <Field type="text" name={`details.${lang.code}`} className="w-full" />
              </div>
            ))}

            {/* Shape */}
            <div className="mb-4">
              <label className="block mb-1">Shape</label>
              <Field as="select" name="shape" className="w-full">
                <option value="">Select Shape</option>
                <option value="ROUND">ROUND</option>
                <option value="OVAL">OVAL</option>
                <option value="EMERALD">EMERALD</option>
                <option value="CUSHION">CUSHION</option>
                <option value="MARQUISE">MARQUISE</option>
                <option value="PRINCESS">PRINCESS</option>
                <option value="PEAR">PEAR</option>
                <option value="HEART">HEART</option>
                <option value="RADIANT">RADIANT</option>
              </Field>
            </div>

            {/* File Upload */}
            <div className="mb-4">
              <h3 className="font-semibold mb-2">Upload Images/Videos</h3>
              <FileMultiUpload
                name="images"
                values={values.images}
                setFieldValue={setFieldValue}
              />
            </div>

            {/* Main Image Selection */}
            {values.images.length > 0 && (
              <div className="mb-4">
                <h3 className="font-semibold mb-2">Select Main Image</h3>
                {values.images
                  .filter((item) => item.type === "image")
                  .map((image, index) => (
                    <div key={index} className="flex mb-2">
                      <Field
                        type="radio"
                        name="mainImage"
                        className="h-5 w-5"
                        value={image.url}
                        checked={values.mainImage === image.url}
                        onChange={() => setFieldValue("mainImage", image.url)}
                      />
                      <img src={image.url} alt="image" className="h-10 w-10 object-cover ml-2" />
                    </div>
                  ))}
              </div>
            )}

            {/* Variants */}
           <div className="mb-4">
              <FieldArray name="variants">
                {({ push, remove }) => (
                  <div>
                    <h3 className="font-semibold mb-2">Variants</h3>
                    {values.variants.map((variant, index) => (
                      <div key={index} className="mb-4 bg-blue-100 p-4 rounded">
                        <div className="flex gap-4">
                          <Field as="select" name={`variants[${index}].size`}>
                            <option value="">Select Size</option>
                            {sizes.map((size) => (
                              <option key={size._id} value={size._id}>
                                {size.size}
                              </option>
                            ))}
                          </Field>

                          <Field as="select" name={`variants[${index}].metal`}>
                            <option value="">Select Metal</option>
                            {metals.map((metal) => (
                              <option key={metal._id} value={metal._id}>
                                {metal.name}
                              </option>
                            ))}
                          </Field>
                        </div>

                        {/* Prices */}
                        <div className="mt-2">
                          <h4 className="font-semibold mb-1">Prices</h4>
                          {currencies.map((currency, currencyIndex) => (
                            <div key={currency._id} className="flex gap-2">
                              <label className="block mb-1">
                                {currency.name}
                              </label>
                              <Field
                                type="number"
                                name={`variants[${index}].prices[${currencyIndex}].price`}
                                className="w-full"
                                placeholder={`Price in ${currency.name}`}
                              />
                            </div>
                          ))}
                        </div>

                        <button
                          type="button"
                          onClick={() => remove(index)}
                          className="mt-2 text-red-600"
                        >
                          Remove Variant
                        </button>
                      </div>
                    ))}
                    <button
                      type="button"
                      onClick={() =>
                        push({
                          size: "",
                          metal: "",
                          prices: currencies.map((currency) => ({
                            currency: currency._id,
                            price: "",
                          })),
                        })
                      }
                      className="mt-2 bg-blue-500 text-white px-4 py-2 rounded"
                    >
                      Add Variant
                    </button>
                  </div>
                )}
              </FieldArray>
            </div>

            {/* Submit Button */}
            <button
              type="submit"
              className="bg-green-500 text-white px-4 py-2 rounded"
            >
              {edit ? "Update" : "Submit"} Product
            </button>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default AddProduct;
