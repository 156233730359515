// App.js
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./components/login";
import Layout from "./layout/layout.jsx";
import Currency from "./components/currency.jsx";
import Dashboard from "./components/dashboard.jsx";
import Product from "./components/product.jsx";
import Metals from "./components/metals.jsx";
import Sizes from "./components/sizes.jsx";
import { Toaster } from "react-hot-toast";
import NotFound from "./components/notFound.jsx";
import { ProtectedRoute } from "./components/protectedRoute.jsx";
import AddProduct from "./components/addProduct.jsx";
import Appointment from "./components/appointments.jsx";

function App() {
  return (
    <Router>
      <Routes>
        <Route element={<Layout />}>
          <Route index path="/" element={<Login />} />
          <Route index path="/login" element={<Login />} />
        </Route>

        <Route path="*" element={<NotFound />} />
        <Route element={<ProtectedRoute />}>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/product" element={<Product />} />
          <Route path="/product/addProduct" element={<AddProduct />} />
          <Route path="/currency" element={<Currency />} />
          <Route path="/sizes" element={<Sizes />} />
          <Route path="/metals" element={<Metals />} />
          <Route path="/appointments" element={<Appointment />} />
        </Route>
      </Routes>
      <Toaster
        containerStyle={{
          margin: "20px 0 0 - 250px",
          padding: "0 16px",
          height: "40px",
          zIndex: 9999999,
        }}
        toastOptions={{
          success: {
            style: {
              padding: "16px",
              // width: "500vw",
            },
          },
          loading: {
            style: {
              padding: "16px",
            },
          },
          error: {
            style: {
              background: "#f87171",
              padding: "16px",
              color: "#fff",
              // width: "500vw",
            },
          },
        }}
      />
    </Router>
  );
}

export default App;
