import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { MdDelete, MdEdit } from "react-icons/md";
import api from "../utils/api";
import { useNavigate } from "react-router-dom";
import Loader from "./loader";

function Appointment() {
  const navigate = useNavigate();
  const [appointments, setAppointments] = useState([]);
  const [loading, setLoading] = useState(false); // Loader state
  const [editingAppointment, setEditingAppointment] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [appointmentToDelete, setAppointmentToDelete] = useState(null);

  // Fetch appointments from the API
  useEffect(() => {
    fetchAppointments();
  }, []);

  const fetchAppointments = async () => {
    setLoading(true); // Show loader
    try {
      const response = await api.get("/appointment/appointments"); // Adjust this endpoint as necessary
      if(response?.data?.status === 200){
          setAppointments(response.data?.data);
        }
    } catch (error) {
      console.error("Error fetching appointments:", error);
    } finally {
      setLoading(false); // Hide loader
    }
  };

  const handleEdit = (appointment) => {
    setEditingAppointment(appointment);
    setOpenModal(true);
  };

  const handleOpenDeleteDialog = (appointment) => {
    setAppointmentToDelete(appointment);
    setDeleteDialogOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
    setAppointmentToDelete(null);
  };

  const confirmDelete = async () => {
    if (appointmentToDelete) {
      try {
        const response = await api.delete(`/appointment/appointments/${appointmentToDelete._id}`);
        if (response.status === 200) {
          fetchAppointments();
        }
      } catch (error) {
        console.error("Error deleting appointment:", error);
      }
    }
    handleCloseDeleteDialog();
  };

  const handleStatusUpdate = async (id, newStatus) => {
    try {
        const response = await api.patch(`/appointment/appointments/${id}`, { status: newStatus });
        if (response.status === 200) {
            fetchAppointments();
          }
    } catch (error) {
      console.error("Error updating appointment status:", error);
    }
  };
  return (
    <div>
      <Button variant="contained" color="primary" onClick={() => navigate("addappointment")}>
        Add Appointment
      </Button>

      {loading && <Loader />}

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>First Name</TableCell>
              <TableCell>Last Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Phone</TableCell>
              <TableCell>Consultation Type</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Time</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {appointments?.map((appointment) => (
              <TableRow key={appointment._id}>
                <TableCell>{appointment.firstName}</TableCell>
                <TableCell>{appointment.lastName}</TableCell>
                <TableCell>{appointment.email}</TableCell>
                <TableCell>{appointment.phone}</TableCell>
                <TableCell>{appointment.consultationType}</TableCell>
                <TableCell>{new Date(appointment.consultationDate).toLocaleDateString()}</TableCell>
                <TableCell>{appointment.consultationTime}</TableCell>
                <TableCell>{appointment.status}</TableCell>
                <TableCell sx={{
                    display: "flex",
                    gap: 2
                }}>
                  {/* <IconButton onClick={() => handleEdit(appointment)} color="primary">
                    <MdEdit />
                  </IconButton> */}
                  <IconButton onClick={() => handleOpenDeleteDialog(appointment)} color="secondary">
                    <MdDelete />
                  </IconButton>
                  {appointment.status === 'pending' && (
                    <>
                      <Button
                        onClick={() => handleStatusUpdate(appointment._id, 'confirmed')}
                        variant="contained"
                        color="success"
                      >
                        Confirm
                      </Button>
                      <Button
                        onClick={() => handleStatusUpdate(appointment._id, 'canceled')}
                        variant="contained"
                        color="error"
                      >
                        Cancel
                      </Button>
                    </>
                  )}
                  {appointment.status === 'confirmed' && (
                    <Button
                      onClick={() => handleStatusUpdate(appointment._id, 'completed')}
                      variant="contained"
                      color="primary"
                    >
                      Complete
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Delete Confirmation Dialog */}
      <Dialog open={deleteDialogOpen} onClose={handleCloseDeleteDialog}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          Are you sure you want to delete the appointment for "{appointmentToDelete?.firstName} {appointmentToDelete?.lastName}"?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} color="secondary">
            Cancel
          </Button>
          <Button onClick={confirmDelete} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Appointment;
