// api.js (Axios Utility)
import axios from 'axios';
import { deleteCookies, getCookies } from './cookies';

// Create an instance of Axios
const api = axios.create({
  baseURL: 'https://api.ecorocksdenia.com/api', // Base URL for your API
  // baseURL: 'http://localhost:5000/api', // Base URL for your API
  timeout: 10000, // Request timeout
});

// Request interceptor to add Authorization header
api.interceptors.request.use(
    async (config) => {
      const token = await getCookies("token"); // Get the token from cookies
      if (token) {
        config.headers["auth-token"] = token;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

// Response interceptor to handle errors globally (optional)
api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // Handle 401 Unauthorized errors by redirecting to login
    if (error.response && error.response.status === 401) {
      // You can remove cookies and redirect to login
      deleteCookies("token");
      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);

export default api;
