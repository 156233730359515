import React, { useEffect, useState } from "react";
import Header from "./header";
import { Outlet, useNavigate } from "react-router-dom";
import Sidebar from "./sidebar";
import { getCookies } from "../utils/cookies";

function Layout() {
  const [isSidebarOpen, setSidebarOpen] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false); // State to track authentication status
  const navigate = useNavigate();
  
  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  useEffect(() => {
    const checkAuthentication = async () => {
      const token = await getCookies("token");
      setIsAuthenticated(token !== undefined); // Set authenticated state based on token presence
    };

    checkAuthentication(); // Call the function to check authentication status
  }, [navigate]);

  return (
    <>
      <div className="flex h-screen flex-col max-h-screen max-w-screen">
        <div className="flex flex-1">
          {isAuthenticated && ( // Only render the sidebar if authenticated
            <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
          )}
          <div className="bg-gray-100 w-full overflow-y-auto h-[100vh]">
            <Header toggleSidebar={toggleSidebar} />
            <div className="md:p-10 p-4">
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Layout;
