import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  CircularProgress, // Import the CircularProgress component for the loader
} from "@mui/material";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import api from "../utils/api";
import { MdDelete, MdOutlineModeEdit } from "react-icons/md";
import Loader from "./loader";

// Yup validation schema for the form
const validationSchema = Yup.object({
  size: Yup.string().required("Size is required"),
});

function Sizes() {
  const [sizes, setSizes] = useState([]);
  const [open, setOpen] = useState(false);
  const [editingSize, setEditingSize] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [sizeToDelete, setSizeToDelete] = useState(null);
  const [loading, setLoading] = useState(false); // Loading state

  // Fetch the sizes from the backend when the component mounts
  useEffect(() => {
    fetchSizes();
  }, []);

  const fetchSizes = async () => {
    setLoading(true); // Start loading
    try {
      const response = await api.get("/variant/sizes/sizes");
      setSizes(response?.data?.data);
    } catch (error) {
      console.error("Error fetching sizes:", error);
    } finally {
      setLoading(false); // End loading
    }
  };

  const handleClickOpen = (size = null) => {
    setEditingSize(size);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEditingSize(null);
  };

  const handleOpenDeleteDialog = (size) => {
    setSizeToDelete(size);
    setDeleteDialogOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
    setSizeToDelete(null);
  };

  const confirmDelete = async () => {
    if (sizeToDelete) {
      setLoading(true); // Start loading
      try {
        const response = await api.delete(
          `/variant/sizes/sizes/${sizeToDelete._id}`
        );
        if (response.status === 200) {
          fetchSizes();
        }
      } catch (error) {
        console.error("Error deleting size:", error);
      } finally {
        setLoading(false); // End loading
      }
    }
    handleCloseDeleteDialog();
  };

  const handleSubmit = async (values, { resetForm }) => {
    setLoading(true); // Start loading
    try {
      let response;
      if (editingSize) {
        response = await api.put(
          `/variant/sizes/sizes/${editingSize._id}`,
          values
        );
      } else {
        response = await api.post("/variant/sizes/sizes", values);
      }

      if (response.status === 200) {
        fetchSizes();
      }

      resetForm();
      handleClose();
    } catch (error) {
      console.error("Error saving size:", error);
    } finally {
      setLoading(false); // End loading
    }
  };

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        onClick={() => handleClickOpen(null)}
      >
        Add Size
      </Button>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Size</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sizes?.map((size) => (
              <TableRow key={size._id}>
                <TableCell>{size.size}</TableCell>
                <TableCell>
                  <IconButton
                    onClick={() => handleClickOpen(size)}
                    color="primary"
                  >
                    <MdOutlineModeEdit />
                  </IconButton>
                  <IconButton
                    onClick={() => handleOpenDeleteDialog(size)}
                    color="secondary"
                  >
                    <MdDelete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

        {/* Loader Display */}
        {loading && <Loader />}

        {/* Size Form Dialog */}
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>{editingSize ? "Edit Size" : "Add Size"}</DialogTitle>
          <Formik
            initialValues={{
              size: editingSize ? editingSize.size : "",
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ errors, touched }) => (
              <Form>
                <DialogContent>
                  <div>
                    <label htmlFor="size">
                      Size Name <span>*</span>
                    </label>
                    <Field
                      type="text"
                      id="size"
                      name="size"
                      placeholder="Size Name"
                      className="py-1 px-2.5 min-h-10 w-full rounded-md"
                    />
                    <ErrorMessage
                      name="size"
                      component="div"
                      className="text-red-500"
                    />
                  </div>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose} color="secondary">
                    Cancel
                  </Button>
                  <Button type="submit" color="primary">
                    {editingSize ? "Update" : "Create"}
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </Dialog>

        {/* Delete Confirmation Dialog */}
        <Dialog open={deleteDialogOpen} onClose={handleCloseDeleteDialog}>
          <DialogTitle>Confirm Delete</DialogTitle>
          <DialogContent>
            Are you sure you want to delete the size "{sizeToDelete?.size}
            "?
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDeleteDialog} color="secondary">
              Cancel
            </Button>
            <Button onClick={confirmDelete} color="primary">
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </TableContainer>
    </div>
  );
}

export default Sizes;
